/*eslint no-console: 0 */
'use strict'

const camelCased = str => str.replace(/-([a-z])/g, g => g[1].toUpperCase())
const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1)
const finalCased = str => str.toUpperCase().replace(/-/g, "\_")
const titleCased = str => capitalizeFirstLetter(camelCased(str))
const stringSort = (a, b) => a.localeCompare(b)

const removeDash = (str) => {
  str = str.replace(/-/g, ' ')
  str = str.replace(/\//g, ' / ')
  return str
}
  
module.exports = {
  camelCased,
  capitalizeFirstLetter,
  finalCased,
  titleCased,
  stringSort,
  removeDash,
}
