import React, { Component } from 'react'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import { AutoSizer, List, WindowScroller } from 'react-virtualized'
import { DebounceInput } from 'react-debounce-input'
import Toggle from '../components/toggle'
import Thumbnail from '../components/thumbnail'
import { removeDash } from '../../string-utils'
import styles from './style.css'
import NewUiToggle from "../components/newui-toggle";

function matchFilter(icon, set, filter) {
  set = set.toLowerCase()
  filter = filter.toLowerCase()

  let { name, section } = icon
  name = name.toLowerCase()
  section = section.toLowerCase()

  return removeDash(name).includes(filter) || section.includes(filter) || set.includes(filter)
}

function iconPath(set, section) { return `icons/${set}/${section}` }
function namespaceHash(set, section, name) { return [set, ...section.split('/'), name].filter(Boolean).join('-') }

const hash = window.location.hash.substring(1)

export default class App extends Component {
  state = {
    selectedId: '',
    dark: false,
    newUi: true,
    filter: '',
    data: [],
    allGroups: [],
    flatData: [],
    chunkSize: 8,
    sticky: false,
    scrollToIndex: -1,
  }

  componentDidMount(){
    if (hash) {
      setTimeout(() => {
        this.setSelected(hash)
        const idx = this.findSelectedIcon(hash)
        
        this.setState({ scrollToIndex: idx })
      }, 500)
    }
    
    window.addEventListener('scroll', this.handleScroll, false)
    window.addEventListener('resize', this.handleWindowResize, false)
  }

  componentWillMount() {
    fetch('data.json', { credentials: 'include' })
      .then(results => results.json())
      .then(data => {
        const allGroups = []
        
        data.map((d, idx) => {
          //if (idx == 0) return
          const { set, icons, sections } = d
          
          const g = sections.map(section =>
            icons.filter(icon => 
              icon.section === section))
              
          g.map((g, idx) => allGroups.push({ set, section: sections[idx], icons: g }))          
        })
        allGroups.sort((a, b) => a.set.localeCompare(b.set))
            
        this.setState({ data, allGroups, chunkSize: this.getChunkSize() }, () => this.refreshIcons())
      })
  }

  refreshIcons() {
    const { allGroups, filter, newUi, chunkSize } = this.state
    const flatData = []
    let count = 0

    allGroups.map(group => {
      const { set, section } = group
      if (newUi && !section.includes('expui') && !section.includes('newui')) return

      const sortedIcons = this.filterIcons(group, filter)

      if (sortedIcons.length > 0) {
        flatData.push({ type: 'section', set, section, count: sortedIcons.length })
        
        for (let i = 0; i < sortedIcons.length; i += chunkSize) {
            const chunk = sortedIcons.slice(i, i + chunkSize)
            count += chunk.length
            flatData.push({ type: 'icons', icons: [...chunk], set, section })
        }
        flatData.push({ type: 'divider' })
      }
    })
    console.log(`Icon count: ${count}`)

    this.setState({ flatData: [...flatData] })
  }

  filterIcons(group, filter) {
    const { set, icons } = group

    return icons
      .filter(icon => {
        return matchFilter(icon, set, filter)
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  findSelectedIcon(hash) {
    const { flatData } = this.state

    let foundIndex = -1

    for (let i = 0; i < flatData.length; i++) {
      const group = flatData[i]
      const { type, set, section } = group

      if (type === 'icons') {
        const { icons } = group
        for (let j = 0; j < icons.length; j++) {
          const { name } = icons[j]
          const namespace = namespaceHash(set, section, name)
          if (hash === namespace) {
            foundIndex = i
            break
          }
        }
      }
      
      if (foundIndex != -1) break
    }

    return foundIndex
  }

  getChunkSize() {
    const { innerWidth } = window
    let chunkSize = 8

    if (innerWidth <= 800) {
      chunkSize = 4
    } else if (innerWidth <= 960) {
      chunkSize = 5
    } else if (innerWidth <= 1180) {
      chunkSize = 6
    }
  
    return chunkSize
  }

  handleWindowResize = () => {
    this.setState({ chunkSize: this.getChunkSize() }, () => this.refreshIcons())
  }

  handleScroll = e => {
    const { scrollY: scrollTop } = e.currentTarget
    const isSticky = scrollTop >= 200
    const { sticky } = this.state
    if(sticky !== isSticky)
      this.setState({ sticky:isSticky })
  }

  clearScrollToIndex() {
    this.setState({ scrollToIndex: -1 })
  }

  renderIcons() {
    const { allGroups } = this.state
    return (
      allGroups.map(({ set, section, icons }, idx) => {    
        return this.renderGroup(icons, set, section, idx)
      })
    )
  }

  renderIcons() {
    const { flatData, scrollToIndex, chunkSize } = this.state
    this.thumbNailRenderer = this.thumbNailRenderer.bind(this)
    this.getRowHeight = this.getRowHeight.bind(this)
    this.clearScrollToIndex = this.clearScrollToIndex.bind(this)

    const getChunkStyle = size => {      
      let chunkStyle

      switch (size) {
        case 6: 
          chunkStyle = styles.mid
          break
        case 5:
          chunkStyle = styles.small
          break
        case 4:
          chunkStyle = styles.xsmall
          break
        default:
          chunkStyle = styles.large
      }

      return [styles.grid, chunkStyle].join(' ')
    }    

    return (
      <WindowScroller onScroll={this.clearScrollToIndex}>
        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
          <AutoSizer disableHeight>
            {({ width }) => {
              // console.log('scroll to index', scrollToIndex)
              // console.log('scrollTop', scrollTop)
              // console.log('window.scrollY', window.scrollY)

              const scrollProps = {
                isScrolling,
                onScroll: onChildScroll,
                scrollToAlignment: 'center',
                scrollToIndex
              }
              if (scrollToIndex === -1) {
                scrollProps.scrollTop = scrollTop
              }

              return (
                <div ref={registerChild}>
                  <List
                    ref={el => { window.listEl = el }}
                    className={getChunkStyle(chunkSize)}
                    autoHeight
                    height={height}
                    {...scrollProps}
                    width={width}
                    rowCount={flatData.length}
                    rowHeight={this.getRowHeight}
                    rowRenderer={this.thumbNailRenderer}
                  />
                </div>
              )
            }}
            </AutoSizer>
        )}
      </WindowScroller>
    )
  }

  getRowHeight({ index }) {
    const { flatData } = this.state
    const icon = flatData[index]

    switch (icon.type) {
      case 'section':
        return 25
      case 'divider':
        return 100
      default:
        return 140
    }
  }

  thumbNailRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    const { flatData, dark: darkTheme, selectedId } = this.state
    const group = flatData[index]
  
    const { type } = group
    
    if (type === 'section') {
      const { set, section } = group
      
      return (
        <div className={styles.section} key={key} style={style}>
          <h4>{removeDash(`${set}${section ? '/' + section : ''}`)}</h4>
        </div>
      )
    } else if (type === 'divider') {
      return (
        <div className={styles.divider} key={key} style={style} />
      )
    } else {
      const { set, section, icons } = group
      
      return (
        <ul key={key} className={styles.iconRow} style={style}>
          {icons.map(icon => {
            const { name, kind, dark, sizes, variants } = icon

            const namespace = namespaceHash(set, section, name)

            let iconDark = darkTheme ? dark : darkTheme
            if (variants === 1 && dark) iconDark = true

            const filename = this.generateImageUrl(section, set, name, sizes.length > 1, iconDark, kind, variants)
          
            const classes = []
            if (icon.recent === true) classes.push(styles.newBadge)
            if (namespace === selectedId) classes.push(styles.active)
            if (icon.inverted === true) classes.push(styles.inverted)
            let width = 16
            let height = 16

            if (kind === 'svg') {
              width = 32
              height = 32
            } else if (sizes.length) {
              const ssize = sizes[1]
              if (ssize) {
                width = ssize[0]
                height = ssize[1]
              } else {
                width = sizes[0][0]
                height = sizes[0][1]
              }
            }

            // Cap dimensions for larger icons
            width = Math.min(width, 80)
            height = Math.min(height, 80)

            return (
              <li key={namespace} id={namespace} className={classes.join(' ')} onClick={e => {
                e.preventDefault()
                e.stopPropagation()

                this.setSelected(namespace)
              }}>
                <Thumbnail src={filename} width={width} height={height} />
                <div className={styles.caption}>{removeDash(icon.name)}</div>
              </li>
            )
          })}
        </ul>
      )
    }
  }

  handleFilter = e => {
    const { value } = e.target
    this.setState({ filter: value ? value : '' }, () => this.refreshIcons())
    //this.setState({ scrollToIndex: value ? parseInt(value, 10) : -1 }, () => this.refreshIcons())
    this.setSelected()
  }

  handleDownload(icon, set) {
    const urls = []
    let { name, section, hiDPI, dark, kind } = icon

    const retinaStr = '@2x'
    const darkStr = '_dark'
    if (section !== '') section += '/'

    if (kind === 'svg') {
      urls.push(`${iconPath(set, section)}${name}.${kind}`)
      if (dark) urls.push(`${iconPath(set, section)}${name}${darkStr}.${kind}`)
    } else {
      urls.push(`${iconPath(set, section)}${name}.${kind}`)
      if (hiDPI) urls.push(`${iconPath(set, section)}${name}${retinaStr}.${kind}`)
      if (dark) urls.push(`${iconPath(set, section)}${name}${darkStr}.${kind}`)
      if (dark && hiDPI) urls.push(`${iconPath(set, section)}${name}${retinaStr}${darkStr}.${kind}`)
    }

    Promise.all(urls.map(url =>
      fetch(url).then(resp => resp.blob())
    )).then(blobs => {
      const zip = new JSZip()
      blobs.map((blob, idx) => {
        const filename = urls[idx].substring(urls[idx].lastIndexOf('/') + 1)
        console.log(filename)
        console.log(blob)
        zip.file(filename, blob, { base64: true })
      })

      zip.generateAsync({ type: 'blob' })
        .then(function(content) {
          saveAs(content, `${name}.zip`)
        })      
    })
  }

  setSelected(hash = '') {
    const { selectedId } = this.state
    if (selectedId === hash) hash = '' // click again to deselect
    this.setState({ selectedId: hash })
    const suffix = hash ? `/#${hash}` : '/'

    window.history.scrollRestoration = hash !== '' ? 'manual' : 'auto' // need to prevent browser restoration when hash set
    window.history.replaceState({}, document.title, suffix)
  }

  generateImageUrl(section, set, name, retina = false, dark = false, kind){
    const retinaStr = retina ? '@2x' : ''
    const darkStr = dark ? '_dark' : ''
    if (section !== '') section += '/'

    if (kind === 'svg')
      return `${iconPath(set, section)}${name}${darkStr}.svg`    
    
    return `${iconPath(set, section)}${name}${retinaStr}${darkStr}.png`
  }

  sizeFromImage(sizes){
    const size = sizes[0]
    return size
  }

  generateImage(inverted, sizes, set, section, name, retina = false, dark = false, suffix = '.png'){
    const retinaStr = retina ? '@2x' : ''    
    const darkStr = dark ? '_dark' : ''
    if (section !== '') section += '/'

    const src = `${iconPath(set, section)}${name}${retinaStr}${darkStr}${suffix}`

    let size = sizes[0] || [16,16]
    const [ width, height ] = size
    
    if (sizes.length && retina) size = sizes[1]

    const classes = []
    if(inverted === true) classes.push(styles.transparent)
    if(dark) classes.push(styles.darkBkd)
    return (
      <li className={classes.join(' ')} data-size={size.join('x')} title={src}>
        <img src={src} width={width} height={height} />
      </li>
    )
  }

  generateImageList(icon, set){
    if(!icon) return
    const { section, name, kind, hiDPI, sizes, dark, inverted, variants } = icon
    let iconLight, iconLightRetina, iconDark, iconDarkRetina

    const onlyDark  = variants === 1 && dark

    if (kind === 'svg') {
      iconLight       = !onlyDark ? this.generateImage(inverted, sizes, set, section, name, false, false, '.svg') : null
      iconDark        = dark ? this.generateImage(inverted, sizes, set, section, name, false, true, '.svg') : null
    } else {
      iconLight       = this.generateImage(inverted, sizes, set, section, name)
      iconLightRetina = hiDPI ? this.generateImage(inverted, sizes, set, section, name, true) : null
      iconDark        = dark ? this.generateImage(inverted, sizes, set, section, name, false, true) : null
      iconDarkRetina  = dark && hiDPI ? this.generateImage(inverted, sizes, set, section, name, true, true) : null
    }

    return (
      <ul className={styles.footerImageList}>
        {iconLight}
        {iconLightRetina}
        {iconDark}
        {iconDarkRetina}
      </ul>
    )
  }

  generateFooter() {
    const { data, selectedId } = this.state
    const footerClasses = [styles.footer]

    let foundIcon, foundSet
    for (let i = 0; i < data.length; i++) {
      const { icons, set } = data[i]
      foundSet = set
      foundIcon = icons.find(icon => {
        const namespace = namespaceHash(foundSet, icon.section, icon.name)
        return namespace === selectedId
      })
      if (foundIcon) break
    }

    if(selectedId) footerClasses.push(styles.showFooter)
    return (
      <div className={footerClasses.join(' ')}>
        {foundIcon && (
          <div className={styles.footerLabels}>
            <h5 className={styles.footerIconName}>{foundIcon.name}</h5>
            {foundIcon.java && <span className={styles.footerJava}>{foundIcon.java}</span>}
          </div>
        )}        
        {this.generateImageList(foundIcon, foundSet)}
        <div className={styles.footerRight}>
          <div className={styles.button} onClick={() => this.handleDownload(foundIcon, foundSet)}>
            <img src="../download.svg" />
            <span>{foundIcon ? foundIcon.kind.toUpperCase() : null}</span>
          </div>
        </div>
      </div>
    )
  }

  handleThemeToggle = isDark => {
    if (isDark) document.body.className = styles.dark
    if (!isDark) document.body.className = ''
  }

  handleNewUiToggle = () => {
    const { newUi } = this.state
    this.setState({ newUi: !newUi }, () => this.refreshIcons())
  }

  render(){
    const { filter, sticky, dark, newUi } = this.state
    const classes = [styles.container]
    if(dark) classes.push(styles.dark)
    if (sticky) classes.push(styles.fixed)
    
    let timestamp = BUILD_TIMESTAMP ? (new Date(BUILD_TIMESTAMP)) : Date.now()
    timestamp = timestamp.toISOString()
    timestamp = timestamp
                  .substring(0, timestamp.lastIndexOf('T'))
                  .replace(/-/g, '.')

    return(
      <div className={classes.join(' ')} >
        <div className={styles.searchBox}>
          <div className={styles.searchInner}>
            <DebounceInput
              type="text"
              placeholder="Search"
              aria-label="Search"
              value={filter}
              debounceTimeout={100}
              onChange={this.handleFilter}
            />
            <div className={styles.right}>
              <NewUiToggle dark={dark} enabled={newUi} onChange={this.handleNewUiToggle} />
              <Toggle onChange={dark => {
                this.handleThemeToggle(dark)
                this.setState({ dark })
              }}/>
            </div>
            <button className={styles.clear} style={{display:filter?'':'none'}} onClick={()=> {
              this.setState({ filter:''}, () => this.refreshIcons())
              this.setSelected()
            }}/>
          </div>
        </div>
        <article>
          <p>
            For plugin developers:<br />
            &mdash; Most icons are licensed under Apache 2 license. Please check SVG content for the corresponding header.<br />
            &mdash; To use an existing icon, select the icon and use the icon reference in the plugin source code.<br />
            &mdash; To modify an icon, select and download the SVG/PNG file(s). Modify the icon following the <a href="https://jetbrains.design/intellij/principles/icons/" target="_blank">guidelines</a> and add it to the plugin.
            <br /><br />
            <span className={styles.timestamp}>Last updated on {timestamp}</span>
          </p>
          <div className={styles.divider}/>
          {this.renderIcons()}
        </article>
        {this.generateFooter()}
      </div>
    )
  }
}
  