import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.css'

export default class Thumbnail extends Component {
  static propTypes = {
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
  }

  render() {
    const { src, width, height } = this.props

    return (
        <div className={style.wrapper}>
          <img width={width} height={height} src={src} />
        </div>
    )
  }
}