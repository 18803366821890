import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.css'

export default class NewUiToggle extends Component {
  static propTypes = {
    dark: PropTypes.bool,
    enabled: PropTypes.bool,
    onChange: PropTypes.func,
  }
  
  render() {
    const { dark, enabled } = this.props
    const classes = [style.toggle]
    if (dark) classes.push(style.dark)
    if (enabled) classes.push(style.enabled)

    return (
      <div title="Toggle New UI icons" className={classes.join(' ')} onClick={this.props.onChange}>
        <span>New UI</span>
      </div>
    )
  }
}