import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.css'

export default class Toggle extends Component {
  static propTypes = {
    onChange:PropTypes.func,
  }

  state = {
    dark:false,
    lucky:false
  }

  setDark(dark){
    this.setState({ dark })
    this.setLucky(false)
    if(typeof this.props.onChange === 'function')
      this.props.onChange(dark)

  }

  setLucky(lucky){
    const { dark } = this.state
    this.setState({ lucky })
    const images = [...document.querySelectorAll('img')]
    images.map(img => {
      if (lucky) {
        img.style.filter = 'sepia(500%) hue-rotate('+ (~~(Math.random() * (dark ? 255 : 150))) +'deg)'
      } else {
        img.style.removeProperty('filter')
      }
    })
  }

  render() {
    const { dark, lucky } = this.state
    const lightClasses = [style.light]
    const darkClasses = [style.dark]
    const luckyClasses = [style.lucky]

    if (lucky) {
      luckyClasses.push(style.active)
    } else if(dark) {
      darkClasses.push(style.active)
    } else {
      lightClasses.push(style.active)
    }

    return (
      <ul className={style.group} title="Toggle theme">
        <li className={lightClasses.join(' ')} title="Light" onClick={()=> this.setDark(false)}></li>
        <li className={darkClasses.join(' ')} title="Dark"  onClick={()=> this.setDark(true)}></li>
        {/* <li className={luckyClasses.join(' ')} title="I'm feeling lucky!" onClick={()=> this.setLucky(true)}></li> */}
      </ul>
    )
  }
}
