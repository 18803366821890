import ReactDOM from 'react-dom'
import React from 'react'
import App from './app'
import Sketch from './sketch'
import Figma from './figma'
import './style.css'

const root = document.getElementById('root')

function valueFromParam(name){
  const url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if(!results) return null
  if(!results[2]) return ''
  const result = decodeURIComponent(results[2].replace(/\+/g, ' '))
  return result
}

export function getParameterByNameAndEval(name){
  const result = valueFromParam(name)
  return !!(result === 'true' || +result)
}

const viewFromParam = () => {
  //root.style.overflowY = 'scroll'
  if (getParameterByNameAndEval('sketch')) return <Sketch />
  if (getParameterByNameAndEval('figma')) return <Figma />

  return <App/>
}

if (window.parent) {
  document.addEventListener('click', () => {
    window.parent.postMessage('iframeClick', '*')
  })
}

ReactDOM.render(viewFromParam(), root)
