import React, { Component } from 'react'
import Toggle from '../components/toggle'
import { removeDash } from '../../string-utils'
import style from './style.css'

function matchFilter(icon, set, filter) {
  set = set.toLowerCase()
  filter = filter.toLowerCase()

  let { name, section } = icon
  name = name.toLowerCase()
  section = section.toLowerCase()

  return removeDash(name).includes(filter) || section.includes(filter) || set.includes(filter)
}

function iconPath(set, section) { return `icons/${set}/${section}` }
function namespaceHash(set, section, name) { return [set, ...section.split('/'), name].filter(Boolean).join('-') }

export default class SketchView extends Component {
  state = {
    dark: false,
    large: false,
    filter: '',
    data: [],
    allGroups: [],
    sections:[],
    icons: []
  }

  componentWillMount(){
    fetch('data.json', { credentials: 'include' })
      .then(results => results.json())
      .then(data => {
        const allGroups = []
        data.map(d => {
          const { set, icons, sections } = d
          const g = sections.map(section => icons.filter(icon => icon.section === section))
          g.map((g, idx) => allGroups.push({ set, section: sections[idx], icons: g }))          
        })
        this.setState({ data, allGroups })
        this.searchInput.focus()
      })

    window.addEventListener('blur', this.handleWindowBlur, false)
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.handleWindowBlur, false)
  }

  handleWindowBlur = e => {
    window.location.hash = 'onWindowDidBlur'
  }
  
  handleFilter = e => {
    this.setState({ filter: e.target.value })
  }

  generateImageUrl(section, set, name, retina = false, dark = false, kind){
    const retinaStr = retina ? '@2x' : ''
    const darkStr = dark ? '_dark' : ''
    if (section !== '') section += '/'

    if (kind === 'svg')
      return `${iconPath(set, section)}${name}${darkStr}.svg`    
    
    return `${iconPath(set, section)}${name}${retinaStr}${darkStr}.png`
  }

  handleDragEnd = e => {
    const temp = new Image()
    temp.addEventListener('load', (e) => {      
      const data = {
        height: e.target.height,
        width: e.target.width 
      }
      
      window.draggedIcon = encodeURI(JSON.stringify(data))
      window.location.hash = 'dragEnd'
    }, false)
    temp.src = e.target.src
  }

  renderIcons() {
    const { allGroups } = this.state
    return (
      allGroups.map(({ set, section, icons }, idx) => {    
        return this.renderGroup(icons, set, section, idx)
      })
    )
  }
  
  renderGroup(group, set, section, idx) {
    const { filter, dark, selectedId } = this.state
    const children = group.filter(icon => matchFilter(icon, set, filter)).length
    const sortedGroup = group.sort((a, b) => a.name.localeCompare(b.name))
    
    return (
      <div key={idx} style={{ display:children ? '' : 'none' }} className={style.section}>
        <h4>{removeDash(`${set}/${section}`)} <span>{children}</span></h4>
        <ul>
          {
            sortedGroup.map((icon, idx) => {
              const namespace = namespaceHash(set, icon.section, icon.name)
              if(matchFilter(icon, set, filter)){
                let iconDark = dark ? icon.dark : dark
                if (icon.variants === 1 && icon.dark) iconDark = true

                const filename = this.generateImageUrl(icon.section, set, icon.name, icon.sizes.length > 1, iconDark, icon.kind)
                
                const classes = []
                if(icon.recent === true) classes.push(style.newBadge)
                if(namespace === selectedId) classes.push(style.active)
                if(icon.inverted === true) classes.push(style.inverted)
                let width = 16
                let height = 16         

                if (icon.kind === 'svg') {
                  width = 32
                  height = 32
                } else if (icon.sizes.length) {                        
                  const ssize = icon.sizes[1]
                  if (ssize) {
                    width = ssize[0]
                    height = ssize[1]
                  } else {
                    width = icon.sizes[0][0]
                    height = icon.sizes[0][1]
                  }
                }      

                // Cap dimensions for larger icons
                width = Math.min(width, 80)
                height = Math.min(height, 80)

                return (
                  <li key={idx} id={namespace} className={classes.join(' ')} onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}>
                    <div className={style.wrapper}>
                      <img className={style.icon} src={filename} width={width} height={height} onDragEnd={this.handleDragEnd} />
                    </div>
                    <div className={style.caption} title={removeDash(icon.name)}>{removeDash(icon.name)}</div>
                  </li>
                )
              }
              return null
            })
            }
        </ul>
        <div className={style.divider}/>
      </div>
    )
  }

  render(){
    const { filter, dark } = this.state
    const classes = [style.container]
    if(dark) classes.push(style.dark)
    return(
      <div className={classes.join(' ')} >
        <div className={style.searchBox}>
          <div className={style.searchInner}>
            <input ref={(input) => { this.searchInput = input; }} placeholder="Search" aria-label="Search" value={filter} onChange={this.handleFilter} spellCheck="false" />
            <div className={style.right}>
              <Toggle onChange={dark => this.setState({ dark })}/>
            </div>
            <button className={style.clear} style={{display:filter?'':'none'}} onClick={()=> {
              this.setState({ filter:''})
            }}/>
          </div>
        </div>      
        <div className={style.grid} >
          {this.renderIcons()}
        </div>        
      </div>
    )
  }
}