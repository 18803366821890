exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./newui.svg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./newui_dark.svg"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./check.svg"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./check_dark.svg"));

// Module
exports.push([module.id, ".toggle___2_4sT {\n  height: 28px;\n  display: flex;\n  align-items: center;\n  padding: 0 12px 0 30px;\n  border-radius: 14px;\n  margin-right: 12px;\n  \n  background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n  background-size: 16px;\n  background-position: 8px center;\n  background-repeat: no-repeat;\n  \n  color: #643CB8 !important;\n  transition: background-color 150ms ease-out;\n}\n\n.toggle___2_4sT.dark___1O7rB {\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n  color: #C4A0F3 !important;\n}\n\n.toggle___2_4sT:hover {\n  background-color: #FAF5FF;\n}\n\n.toggle___2_4sT.dark___1O7rB:hover {\n  background-color: #433358;\n}\n\n.toggle___2_4sT.enabled___2aEb- {\n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n  background-color: #EFE5FF;\n}\n\n.toggle___2_4sT.dark___1O7rB.enabled___2aEb- {\n  background-image: url(" + ___CSS_LOADER_URL___3___ + ");\n  background-color: #2F2936;\n}", ""]);

// Exports
exports.locals = {
	"toggle": "toggle___2_4sT",
	"dark": "dark___1O7rB",
	"enabled": "enabled___2aEb-"
};